import SponsorCard from '../elements/SponsorCard';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function SectionSponsors({ sponsors }) {
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '-5% 0% -20% 0%' });

  return (
    <div id="sponsors" className="section">
      <div className="section-content">

        <motion.h1
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
          transition={{ duration: 0.5 }}
          className="section-header"
        >
          Parceiros
        </motion.h1>
        <div className="space-y-3 sm:grid-cols-2 md:space-y-0 sm:grid lg:grid-cols-3 md:gap-5">
          {sponsors.map((sp, i) => <SponsorCard key={i} name={sp.name} path={sp.path} page={sp.page} />)}
        </div>
      </div>
    </div>
  );
}

export default SectionSponsors;