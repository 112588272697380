import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

function EventCard({idx, name, time, description}) {
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '-10% 0% -10% 0%' });
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
        transition={{ duration: 0.25, delay: 0.1 * idx }}
        className="bg-gradient-to-br from-white to-blue-100 dark:from-blue-500 dark:to-blue-800 drop-shadow-lg rounded-sm py-3 px-4 2xl:p-4 hover:scale-[1.025] duration-300 h-full w-full">
          <div className="space-y-3">
            <div className="flex flex-col items-start 2xl:flex-row 2xl:justify-between 2xl:items-center">
              <h3 className="cursor-default text-xl text-blue-700 dark:text-blue-50 font-medium drop-shadow-sm">{name}</h3>
              <h4 className="cursor-default text-xs text-blue-700 dark:text-zinc-300 2xl:text-blue-100 2xl:bg-gradient-to-br 2xl:from-blue-400 2xl:to-blue-600 dark:2xl:text-blue-900 dark:2xl:from-blue-50 dark:2xl:to-blue-100 2xl:px-1 2xl:py-0.5 rounded font-light">{time}</h4>
            </div>
            <p className="text-sm text-blue-500 dark:text-blue-200">{description}</p>
          </div>
        </motion.div>
    );
}

export default EventCard;