import Marquee from "react-fast-marquee";

function HackathonNotice() {
    return (
        <div className="fixed top-0 w-full text-white bg-black bg-opacity-75 z-50 drop-shadow">
            <Marquee className="text-lg py-0.5" autoFill={true} pauseOnHover={true}>
                <p className="mx-10">
                    <span className="mx-3 font-bold">Spring Hackathon '24 <span className="text-xs font-normal">by n.inf</span></span>
                    <span className="mx-3 text-sm"><i class="fa-solid fa-calendar"></i> 10, 11 e 12 de Maio</span>
                    <span className="mx-3 text-sm"><i class="fa-solid fa-location-dot"></i> Sala Ágora, FCT NOVA <span className="text-xs">(Entrada livre)</span></span>
                </p>
            </Marquee>
        </div >
    );
}

export default HackathonNotice;