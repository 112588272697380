import EventCard from '../elements/EventCard';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function SectionEvents({ events }) {
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '-5% 0% -20% 0%' });

  return (
    <div id="events" className="section">
      <div className="section-content">
        <motion.h1
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
          transition={{ duration: 0.5 }}
          className="section-header"
        >
          Os Nossos Eventos
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
          transition={{ duration: 0.5 }}
          className="section-text text-justify"
        >
          Além de participar em eventos organizados por entidades da faculdade o Núcleo de Informática organiza anualmente diversos eventos.
        </motion.p>

        <div className="md:grid md:grid-cols-2 xl:grid-cols-4 gap-4 space-y-4 md:space-y-0">
          {events.map((ev, i) => (<EventCard key={i} idx={i} name={ev['name']} time={ev['time']} description={ev['description']} />))}
        </div>
      </div>
    </div>
  );
}

export default SectionEvents;