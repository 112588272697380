import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function SponsorCard({ name, page, path }) {
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '-10% 0% -10% 0%' });

  return (
    <motion.h1
          ref={ref}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0 }}
          transition={{ duration: 0.5 }}
          className="rounded p-2 flex justify-center items-center">
      <a href={page} target="_blank" rel="noreferrer noopener">
        <img className="h-48 object-center object-scale-down drop-shadow-md dark:opacity-100 hover:opacity-100 hover:scale-110 hover:-rotate-1 duration-200" src={`/images/sponsors/${path}`} alt={name} />
      </a>
    </motion.h1>
  );
}

export default SponsorCard;