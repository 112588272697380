import { useState, useEffect } from 'react';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';

const DarkModeSwitcher = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    document.documentElement.classList.toggle('dark', newDarkMode);
    localStorage.setItem('darkMode', JSON.stringify(newDarkMode));
  };

  useEffect(() => {
    const storedDarkMode = JSON.parse(localStorage.getItem('darkMode'));
    if (storedDarkMode !== null) {
      setIsDarkMode(storedDarkMode);
      document.documentElement.classList.toggle('dark', storedDarkMode);
    } else {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setIsDarkMode(prefersDarkMode);
      document.documentElement.classList.toggle('dark', prefersDarkMode);
      localStorage.setItem('darkMode', JSON.stringify(prefersDarkMode));
    }
  }, []);

  return (
    <button onClick={toggleDarkMode} className="duration-100 p-1.5 rounded-full dark:bg-blue-50 bg-blue-700 text-center items-center flex dark:text-blue-700 text-blue-50 drop-shadow hover:scale-110 active:translate-y-0.5">
      {isDarkMode ? <LightModeOutlinedIcon fontSize="small" /> : <DarkModeOutlinedIcon fontSize="small" />}
    </button>
  );
};

export default DarkModeSwitcher;
