import LogoNinf from '../../assets/logo_ninf.png';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

function SectionHero() {
  const [showArrow, setShowArrow] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setShowArrow(scrollTop <= 500);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="start" className="section relative h-screen flex flex-col items-center justify-center bg-hero-backdrop bg-center bg-no-repeat bg-cover">
      <motion.img
        transition={{ ease: "easeIn", duration: 1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        src={LogoNinf}
        alt="Ninf Logo"
        className="absolute top-[15%] w-[40%] max-w-56 z-10 drop-shadow-[0_0_10px_rgba(0,0,0,0.75)]"
      />
      
      <div className="absolute inset-0 bg-black opacity-50"></div>

      <motion.h1
        initial={{ opacity: 0, scale: 0 }}
        whileHover={{ scale: 1.1, transition: { duration: 0.1 } }}
        animate={{ opacity: 1, scale: 1 }}
        className="select-none cursor-default text-center text-5xl sm:text-6xl lg:text-7xl font-semibold text-transparent bg-clip-text drop-shadow-[0_0_15px_rgba(0,0,0,0.8)] bg-gradient-to-br from-white via-zinc-300 to-white bg-size-200 bg-pos-0 hover:bg-pos-100 duration-500 pb-3"
      >
        Núcleo de Informática
      </motion.h1>
      
      <motion.h2
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.25 }}
        className="select-none cursor-default text-center text-md sm:text-lg lg:text-xl text-white text-opacity-90 drop-shadow-[0_0_5px_rgba(0,0,0,0.75)] tracking-normal hover:tracking-wider duration-500"
      >
        NOVA School of Science & Technology
      </motion.h2>

      <motion.a
        transition={{ duration: 1, opacity: { duration: 0.5 } }} // Adjust the opacity duration
        initial={{ opacity: 0 }}
        whileHover={{ scale: 1.25, transition: { duration: 0.1 } }}
        animate={{ opacity: showArrow ? 1 : 0 }}
        href="#about"
        className="absolute bottom-5 text-white text-opacity-70 text-2xl cursor-pointer hover:scale-110 hover:text-opacity-90 duration-300 active:translate-y-1"
      >
        <i className="fa-solid fa-chevron-down"></i>
      </motion.a>
    </div>
  );
}

export default SectionHero;

