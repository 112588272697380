import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

function TeamCard({ idx, name, description, icon }) {
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '-10% 0% -10% 0%' });
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
      transition={{ duration: 0.4, delay: 0.25 * ((idx+1)%2) }}
      className="py-3 px-4 space-y-2 bg-gradient-to-br from-blue-600 to-blue-800 dark:from-blue-100 dark:to-blue-200 drop-shadow-lg rounded-sm duration-300 group">
      <div className="flex justify-between items-center text-blue-100 dark:text-blue-900 font-medium text-lg">
        <h2 className="cursor-default">{name}</h2>
        <i className={`group-hover:scale-150 group-hover:drop-shadow-sm fa-solid fa-${icon} duration-300`}></i>
      </div>
      <p className="text-blue-300 dark:text-blue-950 text-sm text-justify">{description}</p>
    </motion.div>
  );
}

export default TeamCard;