import content from '../../data/content.json';

import SectionHero from '../sections/SectionHero';
import SectionAbout from '../sections/SectionAbout';
import SectionTeams from '../sections/SectionTeams';
import SectionEvents from '../sections/SectionEvents';
import SectionSponsors from '../sections/SectionSponsors';
import SectionContact from '../sections/SectionContact';
import HackathonNotice from '../elements/HackathonNotice';

const TEAMS = content['teams'];
const SOCIALS = content['socials'];
const SPONSORS = content['sponsors'];
const EVENTS = content['events'];

function App() {
  return (
    <>
      <HackathonNotice />
      <SectionHero />
      <SectionAbout />
      <SectionTeams teams={TEAMS} />
      <SectionEvents events={EVENTS} />
      <SectionSponsors sponsors={SPONSORS} />
      <SectionContact socials={SOCIALS} />
    </>
  );
}

export default App;
