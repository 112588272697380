
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import LOGO_AEFCT from '../../assets/logo_aefct.png';

const VERSION = "1.2b";

function SectionContact({ socials }) {
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '-5% 0% -15% 0%' });

  return (
    <div id="contact" className="section space-y-5">
      <div className="section-content">
        <motion.h1
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
          transition={{ duration: 0.5 }}
          className="section-header"
        >
          Contacto
        </motion.h1>
        <div className="sm:grid sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-5 space-y-5 md:space-y-0">
          <motion.iframe
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.5 }}
            title="map"
            className="w-full min-h-40 h-full sm:col-span-3 md:col-span-1 lg:col-span-3 xl:col-span-2 border-none"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3115.472671025075!2d-9.205782824174285!3d38.66100386061813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1ecada2fb6ad1d%3A0x572c74e3b652d24a!2sN%C3%BAcleo%20de%20Inform%C3%A1tica%20FCT%20NOVA!5e0!3m2!1sen!2spt!4v1710173932445!5m2!1sen!2spt"
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">
          </motion.iframe>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{ duration: 1 }}
            className="md:flex md:items-center">
            <ul className="fa-ul space-y-5 text-lg section-text">
              <li className="-ml-4"><span className="fa-li"><i className="fa-solid fa-envelope drop-shadow"></i></span><a href="mailto:n.inf@ae.fct.unl.pt" rel="noreferrer noopener" className="underline underline-offset-1 decoration-blue-400 hover:decoration-blue-600 dark:decoration-blue-400 dark:hover:decoration-blue-300 dark:hover:text-blue-100 duration-200">n.inf@ae.fct.unl.pt</a></li>
              <li className="-ml-4"><span className="fa-li"><i className="fa-solid fa-map-pin drop-shadow"></i></span>Sala 117 - Edificio II<br /> NOVA School of Science & Technology<br />2829-516 Caparica, Portugal</li>
            </ul>
          </motion.div>
          <div className="flex flex-col items-center justify-center space-y-1">
            <ul className="flex text-blue-700 dark:text-blue-400 opacity-95 gap-1.5">
              {socials.map((sc, i) =>
                <motion.li
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0 }}
                  transition={{ duration: 0.25, delay: 0.2 * i }}
                  key={i} className="p-2">
                  <a href={sc.dest} rel="noreferrer noopener">
                    <i className={`hover:text-blue-600 dark:hover:text-blue-300 duration-300 fa-2xl hover:scale-110 drop-shadow-lg ${sc.icon}`}></i>
                  </a>
                </motion.li>
              )}
            </ul>
          </div>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center justify-center">
            <img src={LOGO_AEFCT} className="max-w-full max-h-24 drop-shadow-lg scale-90 hover:scale-100 duration-100" alt="AEFCT" />
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ duration: 1 }}
          className="text-xs text-blue-400 flex justify-center md:justify-start">
          &copy; n.inf &mdash; 2024 <span className="font-mono ml-3">v{VERSION}</span> <span className="ml-3">Designed by <a href="https://rbtavares.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 dark:text-blue-300">rbtavares</a></span>
        </motion.div>
      </div>
    </div>
  );
}

export default SectionContact;