import TeamCard from "../elements/TeamCard";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function SectionTeams({ teams }) {
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '-5% 0% -20% 0%' });

  return (
    <div className="section">
      <div className="section-content flex flex-col w-full md:flex-row md:space-x-5">
        <div className="space-y-5">
          <motion.h1
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.5 }}
            className="section-header"
          >
            Divisões do Núcleo
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="section-text text-justify"
          >
            Atualmente o Núcleo de Informática está dividido em quatro áreas distintas com diferentes proposta de serviço ao planeamento e realização das atividades que colaboram de forma constante para tornar realidade todos os objetivos traçados pelo núcleo, sendo elas:
          </motion.p>
          <div className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-6">
            {teams.map((tm, i) =>
              <TeamCard key={i} idx={i} name={tm.name} description={tm.description} icon={tm.icon} />
            )}
          </div>
        </div>
        <motion.iframe 
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="md:h-full w-full md:w-auto drop-shadow-lg" title="Discord Invite" src="https://discord.com/widget?id=894630114665578596&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
        </motion.iframe>
      </div>
    </div>
  );
}

export default SectionTeams;