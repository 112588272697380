import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import DarkModeSwitcher from '../elements/DarkModeSwitcher';

function SectionAbout() {
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '-5% 0% -20% 0%' });

  return (
    <div id="about" className="section">
      <div className="section-content">
        <div className="flex justify-between items-center">
          <motion.h1
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.5 }}
            className="section-header"
          >
            Sobre o Núcleo
          </motion.h1>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: inView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <DarkModeSwitcher />
          </motion.div>
        </div>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="section-text text-justify"
        >
          O Núcleo de Informática é constituído por alunos voluntários do mestrado/licenciatura em Engenharia Informática da NOVA School of Science & Technology, e cuja missão principal é representar os alunos, o curso e mostrar que a Informática é uma área diversificada e da qual todos podem usufruir.<br />Para além da representação dos alunos do curso, o núcleo desenvolve várias atividades que têm como objetivo complementar os conhecimentos adquiridos pelos alunos no curso de Engenharia Informática, sendo que a maioria destas atividades está aberta a alunos de outros cursos e outras universidades/faculdades.
        </motion.p>
      </div>
    </div>
  );
}

export default SectionAbout;
